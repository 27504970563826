import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import HomeNew from '../components/home-new'
import Partners from '../components/partners'

const Index = () => {
    const pageTitle = 'Accueil'

    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Page d'accueil</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <HomeNew></HomeNew>
                <Partners></Partners>
            </Layout>
        </>
    )
}

export default Index